import React from "react";
import ReactDOM from "react-dom";
import classes from "./Packagecard.module.css";

function App() {
  return (
    <div className={classes.app}>
      <div className={classes.pagetitle}>Our Packages</div>
      <div className={classes.sectiontitle}>YouTube Marketing Packages</div>
      <div className={classes.packagecontainer}>
        <PackageCard
          title="Basic Package"
          price="₹15,000/month"
          features={[
            "Video shooting (4 videos)",
            "Basic editing",
            "Channel setup",
            "Thumbnail creation",
            "Basic video promotion",
            "SEO content",
            "Video upload",
          ]}
        />
        <PackageCard
          title="Standard Package"
          price="₹20,000/month"
          features={[
            "Video shooting (4 videos)",
            "Intermediate editing",
            "YouTube Ads",
            "Comprehensive optimization",
          ]}
        />
        <PackageCard
          title="Premium Package"
          price="₹25,000/month"
          features={[
            "Video shooting (6 videos)",
            "Advanced editing",
            "Full channel management",
            "Influencer collaboration",
            "Ads campaign",
          ]}
        />
      </div>

      <div className={classes.sectiontitle}>Branding Packages</div>
      <div className={classes.packagecontainer}>
        <PackageCard
          title="Basic Package"
          price="₹4,999 (one-time)"
          features={[
            "Logo design",
            "Business card design",
            "Brand color palette",
            "Typography guide",
          ]}
        />
        <PackageCard
          title="Standard Package"
          price="₹14,999 (one-time)"
          features={[
            "Logo design (with revisions)",
            "Full stationery design",
            "Brand guidelines",
            "Brand storytelling video",
          ]}
        />
        <PackageCard
          title="Premium Package"
          price="₹19,999 (one-time)"
          features={[
            "Unlimited logo revisions",
            "Complete brand identity kit",
            "Professional-grade brand storytelling video",
            "YouTube Ads campaign setup",
          ]}
        />
      </div>

      <div className={classes.sectiontitle}>Digital Marketing Packages</div>
      <div className={classes.packagecontainer}>
        <PackageCard
          title="Basic Package"
          price="₹9,999/month"
          features={[
            "Basic SEO (up to 5 keywords)",
            "Social media marketing (3 posts/week)",
            "Bulk SMS & email (2,000/month)",
            "Content writing (4 blogs/month)",
            "Basic analytics report",
          ]}
        />
        <PackageCard
          title="Standard Package"
          price="₹15,000/month"
          features={[
            "Advanced SEO (up to 10 keywords)",
            "5 posts/week",
            "Bulk SMS/email (3,000/month)",
            "Content writing (6 blogs/month)",
            "YouTube video editing (1 video)",
            "Detailed analytics report",
          ]}
        />
        <PackageCard
          title="Premium Package"
          price="₹25,000/month"
          features={[
            "Full-scale SEO (up to 15 keywords)",
            "Multi-platform marketing (7 posts/week)",
            "Bulk SMS/email (5,000/month)",
            "Advanced content writing (8 blogs/month)",
            "Video shoots (3 videos/month)",
            "Comprehensive KPI report",
          ]}
        />
      </div>
    </div>
  );
}

function PackageCard({ title, price, features }) {
  return (
    <div className={classes.packagecard}>
      <div className={classes.packagetitle}>{title}</div>
      <div className={classes.packageprice}>{price}</div>
      <div className={classes.packagefeatures}>
        {features.map((feature, index) => (
          <div key={index} className={classes.featureitem}>
            <span className={classes.featureicon}>•</span> {feature}
          </div>
        ))}
      </div>
      <button className={classes.getstartedbtn}>Get started</button>
    </div>
  );
}

export default App;

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
