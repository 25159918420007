// File: src/components/Services.js
import React from 'react';
import classes from './Ourservice.module.css';
import youtube from "./youtube (1).png";
import Brand from "./Brand.png";
import dm from "./social-media (4).png";


    const services = [
        {
          icon: dm, // Use the imported image variable
          title: 'Digital Marketing Solutions',
          description: [
            'Social Media Marketing (Facebook, Instagram, LinkedIn)',
            'Bulk SMS & Email Marketing',
            'Search Engine Optimization (SEO)',
            'Google Ads and YouTube Ads',
            'Content Marketing & Blog Writing',
          ],
        },
        {
          icon: youtube, // Use the imported image variable
          title: 'YouTube Video Production & Marketing',
          description: [
            'Video Shooting',
            'Video Editing',
            'YouTube Channel Optimization',
            'YouTube Ads Campaigns',
            'Video SEO & Promotion',
          ],
        },
        {
          icon: Brand, // Use the imported image variable
          title: 'Branding & Identity Creation',
          description: [
            'Logo Design',
            'Stationery Design (Business Cards, Letterheads)',
            'Brand Storytelling Videos',
            'Social Media Branding',
            'Brand Guidelines Creation',
          ],
        },
      ];
      


const Services = () => {
  return (
<div className={classes.services}>
      <h1 className={classes.servicestitle}>Services We Provide</h1>
      <div className={classes.servicesgrid}>
        {services.map((service, index) => (
          <div key={index} className={classes.servicecard}>
            <div className={classes.serviceicon}>
              <img src={service.icon} alt={`${service.title} Icon`} />
            </div>
            <h3 className={classes.servicetitle}>{service.title}</h3>
            <div className={classes.servicedescription}>
              {service.description.map((point, idx) => (
                <div key={idx} className={classes.servicepoint}>
                  <span className={classes.pointbullet}></span>
                  {point}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>   
);
};

export default Services;
