// File: src/components/Testimonials.js
import React from 'react';
import classes from './Testimonial.module.css';
import Arindum from './Arindam.jpeg';
import Santosh from './Santosh.jpeg';
import Baryon from './Dr.png';

const testimonials = [
  {
    stars: 5,
    text: "The team at DiracAI quickly identified growth opportunities we hadn't even considered. Their expertise helped us refine our strategy and achieve sustainable growth.",
    author: 'Dr. Baryon Swain',
    image: Baryon,
  },
  {
    stars: 5,
    text: 'The level of expertise and personalized attention to our unique needs has made them an invaluable partner.',
    author: 'Santosh M.',
    image: Santosh,
  },
  {
    stars: 5,
    text: 'Thanks to DiracAI, we now have a clear business model and a strong value proposition. Their guidance was pivotal in helping us expand our customer base and increase revenue.',
    author: 'Arindum Chaudhary',
    image: Arindum,
  },
];

const Testimonials = () => {
  return (
    <div className={classes.testimonials}>
      <h1 className={classes.title}>What Our Clients Say</h1>
      <div className={classes.testimonialGrid}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className={classes.testimonialCard}>
            <div className={classes.profile}>
              <img
                src={testimonial.image}
                alt={`Photo of ${testimonial.author}`}
                className={classes.profileImage}
              />
            </div>
            <div className={classes.stars}>{'⭐'.repeat(testimonial.stars)}</div>
            <p className={classes.text}>{testimonial.text}</p>
            <p className={classes.author}>{testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
