import { useEffect, useState, useRef } from "react";
import React from 'react';
import img1 from './office.jpeg';  // Main image (appears at top and bottom)
import img2 from './office1.jpg';
import img3 from './office4.jpeg';
import img4 from './office5.jpeg';
import img5 from './office6.jpeg';
import img6 from './office7.jpeg';
import img7 from './office8.jpeg';
import img8 from './office9.jpeg';
import img9 from './office10.jpeg';
import img11 from './office11.jpeg';
import img12 from './office12.jpeg';

import classes from './Gallery.module.css';

const Gallery = (props) => {

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);


 const middleImages = [
    { src: img2, alt: 'DiracAI Office Image 2' },
    { src: img3, alt: 'DiracAI Office Image 3' },
    { src: img4, alt: 'DiracAI Office Image 4' },
    { src: img6, alt: 'DiracAI Office Image 6' }
  ];

  const circularImages = [
    { src: img7, alt: 'DiracAI Office Image 7' },
    { src: img8, alt: 'DiracAI Office Image 8' },
    { src: img9, alt: 'DiracAI Office Image 9' },
    { src: img11, alt: 'DiracAI Office Image 11' },
    { src: img12, alt: 'DiracAI Office Image 12' }
  ];

  return (
    <div className={classes.Container}>
      DiracAI Office Gallery

      <img className={classes.mainImage} src={img5} alt="Main DiracAI Office Image" />
      <div className={classes.circularRow}>
        {circularImages.map((image, index) => (
          <div className={classes.circularFrame} key={index}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>


      <div className={classes.gallery}>
        <div className={classes.middleRow}>
          <img src={img2} alt="DiracAI Office Image 2" />
          <img src={img3} alt="DiracAI Office Image 3" />
        </div>
        <div className={classes.middleRow}>
          <img src={img4} alt="DiracAI Office Image 4" />
          <img src={img6} alt="DiracAI Office Image 6" />
        </div>
      </div>

     
      {/* Second Main Image */}
      <img className={classes.mainImage} src={img1} alt="Main DiracAI Office Image" />
      
    </div>
  );
};

export default Gallery;
