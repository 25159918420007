import React from "react";
import { Link } from "react-router-dom";
import classes from "./Digitalmarketingblock.module.css";
import youtube from './Youtube marketing.png';
import brand from './Branding.png';
import announce from './digital-marketing.png';
import Arindum from "./Arindam.jpeg";
import Sontosh from "./Santosh.jpeg";
// import baryon from './';

const Card = ({ icon, title, description }) => (
  <div className={classes.card}>
    <div className={classes.cardicon}>
      <img src={icon} alt={title} className={classes.cardimage} />
    </div>
    <h3 className={classes.cardtitle}>{title}</h3>
    <p className={classes.carddescription}>{description}</p>
  </div>
);

const MarketingPage = () => {
  return (
    <div className={classes.marketingpage}>
      <h1 className={classes.title}>Branding & Digital Marketing Services</h1>
      <p className={classes.subtitle}>
        Your One-Stop Solution to Build, Promote, and Amplify Your Brand
      </p>

      <div className={classes.cardscontainer}>
        <Card
          icon={youtube}
          title="YouTube Marketing"
          description="Maximize Your Reach on YouTube"
        />
        <Card
          icon={brand}
          title="Branding"
          description="Craft a Distinctive Brand Identity"
        />
        <Card
          icon={announce}
          title="Digital Marketing"
          description="Result-Driven Digital Marketing"
        />
      </div>

      <Link to="/digital-marketing" className={classes.explorebutton}>
        Explore Digital Marketing Solutions
      </Link>
    </div>
  );
};

export default MarketingPage;
