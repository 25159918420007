import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import classes from './Getintouch.module.css';

const GetInTouch = () => {
  return (
    <div className={classes.getintouch}>
      <h1 className={classes.title}>Ready to take your business to the next level with DiracAI Digital Services?</h1>
      <p className={classes.subtitle}></p>

      <p className={classes.ctatext}>
        <Link to="/ContactUs" className={classes.ctalink}>
        Get in Touch with Us Today
        </Link>
      </p>
    </div>
  );
};

export default GetInTouch;
