// File: src/components/AddOnServices.js
import React from 'react';
import classes from './AddonServices.module.css';

const addOnServices = [
 
 
 
  {
    title: 'Custom Graphic Design:',
    description:
      'Starting at ₹ 3,000 onwards',
    pricing: 'Pricing available on request.',
  },
  {
    title: 'Google Ads & Social Media Ads',
    description:
      'Comprehensive ad campaign management to grow your business and improve ROI on Google Ads and social platforms.',
    pricing: '₹ 10,000/month management fee (excluding ad spend).',
  },
  {
    title: 'Custom Graphic Design',
    description:
      'Creative designs tailored to your needs, including logos, brochures, and marketing materials.',
    pricing: 'Starting at ₹ 3,000 onwards.',
  },
];

const AddOnServices = () => {
  return (
    <div className={classes.addonservices}>
      <h1 className={classes.title}>Add-On Services</h1>
      <p className={classes.subtitle}>
        Enhance your packages with the following services:
      </p>
      <div className={classes.servicesgrid}>
        {addOnServices.map((service, index) => (
          <div key={index} className={classes.servicecard}>
            <h3 className={classes.servicetitle}>{service.title}</h3>
            <p className={classes.servicedescription}>{service.description}</p>
            {service.pricing && (
              <p className={classes.servicepricing}>
                <strong>Pricing:</strong> {service.pricing}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddOnServices;
