
import { useEffect, useState, useRef } from "react";
import React from "react";
import classes from "./Digitalmarketing.module.css";
import Whyus from "./whyus.jpg";
import man from "./boy.png";
import youtube from "./youtube (1).png";
import Brand from "./Brand.png";
import dm from "./social-media (4).png";
import PackageCard from "./PackageCard"
import WhyChooseUs from "./WhyChooseUs";
import GetInTouch from "./Getintouch";
import Ourservices from "./Ourservice";
import AddOnServices from "./AddOnServices";
import Testimonial from "./TestimonialsforDM";
import banner from './banner.png';
import Arindum from "./Arindam.jpeg"
import Sontosh from './Santosh.jpeg';
import Baryon from "./Dr.png"

const DigitalMarketing = (props) => {

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);






	return (
    <div className={classes.container}>
      <div className={classes.banner}>
        <img src={banner} className={classes.bannerimg} />
        <div className={classes.bannerhead}>About Our Digital Marketing Services</div>
        <div className={classes.bannersubhead}>In a world dominated by digital innovation, your brand needs a partner who doesn’t just follow trends but creates them. At DiracAI, we specialize in crafting unique, data-driven digital marketing strategies that go beyond the ordinary.

Our team of experts integrates cutting-edge technology with creative solutions to amplify your online presence. Whether it’s crafting viral campaigns, optimizing your content for search engines, or managing your ad spend to deliver the highest ROI, we tailor every solution to align with your goals.

From startups looking to make their mark to established brands aiming for the next level, we are the catalyst for your success.</div>
<div className={classes.para}>“Let’s Create Your Digital Success Story – Start Now!”</div>
      </div>
      {/* <header className={classes.header}>
        <div className={classes.title}>DiracAI Digital</div>
        <div className={classes.subtitle}>
          Delivering cutting-edge digital solutions to elevate your business in
          today’s competitive online world.
        </div>
      </header> */}

      {/* <section className={classes.section}>
        <div className={classes.about}>
          <div className={classes.sectionTitle}>About Us</div>
          <div className={classes.aboutContent}>
            <img src={Whyus} alt="Why DiracAI" className={classes.aboutImage} />
            <div className={classes.text}>
              At DiracAI, we are a dynamic team blending expertise in technology,
              creativity, and business strategy. Founded by IIT Delhi graduates
              and seasoned professionals with 30+ years of experience across
              Banking and eCommerce, we bring unmatched insights to solve your
              most pressing challenges. Fueled by our passion for innovation and
              empowerment, we deliver tailored digital solutions that drive
              growth. Let’s build the future together.
            </div>
          </div>
        </div>
      </section> */}
      <WhyChooseUs />

      {/* <section className={classes.section}>
        <div className={classes.whyUs}>
          <div className={classes.sectionTitle}>Why Choose DiracAI?</div>
          <div className={classes.cards}>
            <div className={classes.card}>
              <img src={man} alt="Affordable Excellence" />
              <div className={classes.cardTitle}>Affordable Excellence</div>
              <div className={classes.cardText}>
                High-impact digital solutions designed to fit every budget,
                empowering businesses of all sizes.
              </div>
            </div>
            <div className={classes.card}>
              <img src={youtube} alt="Tailored for You" />
              <div className={classes.cardTitle}>Tailored for You</div>
              <div className={classes.cardText}>
                Custom strategies crafted to meet the distinct needs of schools,
                startups, and MSMEs.
              </div>
            </div>
            <div className={classes.card}>
              <img src={dm} alt="All-in-One Expertise" />
              <div className={classes.cardTitle}>All-in-One Expertise</div>
              <div className={classes.cardText}>
                From YouTube production to full-scale branding, we offer
                seamless, end-to-end services.
              </div>
            </div>
            <div className={classes.card}>
              <img src={dm} alt="Customer-First Focus" />
              <div className={classes.cardTitle}>Customer-First Focus</div>
              <div className={classes.cardText}>
                Dedicated support and measurable results aligned with your goals.
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* Our Services Section */}
      <Ourservices />
      {/* <section className={classes.section}>
        <div className={classes.services}>
          <div className={classes.sectionTitle}>Our Services</div>
          <div className={classes.serviceList}>
            <div className={classes.service}>
              <img src={youtube} alt="YouTube Marketing" />
              <div className={classes.serviceTitle}>YouTube Marketing</div>
              <div className={classes.serviceText}>
                Whether you’re an educational institution looking to create
                informative content or a business aiming to expand its online
                presence, our YouTube services have you covered: <br />
                - Video Shooting <br />
                - Video Editing <br />
                - YouTube Channel Optimization <br />
                - YouTube Ads Campaigns <br />
                - Video SEO & Promotion
              </div>
            </div>
            <div className={classes.service}>
              <img src={Brand} alt="Branding & Identity" />
              <div className={classes.serviceTitle}>Branding & Identity</div>
              <div className={classes.serviceText}>
                We help businesses define their brand identity from scratch or
                refine existing branding to create a memorable image: <br />
                - Logo Design <br />
                - Stationery Design (Business Cards, Letterheads) <br />
                - Brand Storytelling Videos <br />
                - Social Media Branding <br />
                - Brand Guidelines Creation
              </div>
            </div>
            <div className={classes.service}>
              <img src={dm} alt="Digital Marketing" />
              <div className={classes.serviceTitle}>Digital Marketing</div>
              <div className={classes.serviceText}>
                Leverage our marketing strategies to grow your online presence
                effectively: <br />
                - Social Media Marketing (Facebook, Instagram, LinkedIn) <br />
                - Bulk SMS & Email Marketing <br />
                - Search Engine Optimization (SEO) <br />
                - Google Ads and YouTube Ads <br />
                - Content Marketing & Blog Writing
              </div>
            </div>
          </div>
        </div>
      </section> */}


<PackageCard />

<AddOnServices />
      {/* Add-On Services Section
      <section className={classes.section}>
        <div className={classes.addon}>
          <div className={classes.sectionTitle}>Add-On Services</div>
          <div className={classes.addonText}>
            Enhance your packages with the following services: <br />
            - Professional Website Development <br />
            - Custom Animation Videos <br />
            - Advanced Analytics and Reporting <br />
            - Content Localization Services
          </div>
        </div>
      </section> */}

      {/* Footer Section */}
      <Testimonial />
      <GetInTouch />
      {/* <footer className={classes.footer}>
        <div className={classes.sectionTitle}>Get in Touch</div>
        <div className={classes.footerText}>
          Phone: <a href="tel:+917008639757">+91-7008639757</a> <br />
          Email: <a href="mailto:contact@diracai.com">contact@diracai.com</a> <br />
          Address: HIG-306, K-5, Kalinga Vihar, Bhubaneswar, 751019
        </div>
      </footer> */}
    </div>
  );
};

export default DigitalMarketing;
