import React from 'react';
import classes from './Whychooseus.module.css';

const WhyChooseUs = () => {
  return (
    <div className={classes.whychooseus}>
      <h1 className={classes.headertitle}>
        Why <span className={classes.highlight}>DiracAI</span> is The Right Choice for You ?
      </h1>

      <div className={classes.features}>
        <div className={classes.featurecard}>
          <div className={classes.icon}>💸</div>
          <h3>Affordable Excellence</h3>
          <p>
            High-impact digital solutions designed to fit every budget, empowering businesses of all sizes.
          </p>
        </div>

        {/* <div className={classes.featurecard}>
          <div className={classes.icon}>🛠️</div>
          <h3>Tailored for You</h3>
          <p>
            Custom strategies crafted to meet the distinct needs of schools, startups, and MSMEs.
          </p>
        </div> */}

        <div className={classes.featurecard}>
          <div className={classes.icon}>🔗</div>
          <h3>All-in-One Expertise</h3>
          <p>
            From YouTube production to full-scale branding, we offer seamless, end-to-end services.
          </p>
        </div>

        <div className={classes.featurecard}>
          <div className={classes.icon}>🤝</div>
          <h3>Customer-First Focus</h3>
          <p>
            Dedicated support and measurable results aligned with your goals.
          </p>
        </div>
      </div>

      {/* <div className={classes.ctasection}>
        <div className={classes.ctacontent}>
          <h3>Flexible Learning Schedules</h3>
          <p>
            At expert.io, we understand the importance of balancing learning with a busy lifestyle.
            That's why our courses are available on-demand, allowing you to learn at your own pace, anytime and anywhere.
          </p>
          <button className={classes.ctabutton}>Start Free Trial →</button>
        </div>
      </div> */}
    </div>
  );
};

export default WhyChooseUs;
